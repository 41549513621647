// @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// :root {
//   scroll-behavior: initial;
//   $font-family: "Nunito", sans-serif !important;
// }

$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1200px;
$value_eight: 1355px;
$value_nine: 1550px;

body,
html {
  $font-family: "Nunito",
  sans-serif !important;
}

* {
  $font-family: "Nunito",
  sans-serif !important;
}

$font-family: "Nunito", sans-serif !important;
$font-family2: "Nunito", sans-serif !important;
// $font-family2: "Playfair Display", serif;
$main-color: #5172ff;
$main-color2: #6ba292;
$optional-color: #606060;
$white-color: #ffffff;
$black-color: #221638;
$font-size: 16px;
$transition: 0.5s;

@media only screen and (min-width: 280px) and (max-width: 374px) {

  /* .edemy-nav .navbar-brand {
      max-width: 170px;
    }
    .edemy-nav .navbar-brand > img {
      object-fit: contain;
    } */
  .extraSmall {
    h3 {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .highlightsWrapper {
    li {
      uspWrapper {
        p {
          font-size: 16px;
        }
      }
    }
  }
}

.highlightsWrapper {
  li {
    position: relative;
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    padding-left: 20px;
    padding-bottom: 0.25rem;

    &:before {
      content: "●";
      font-size: 20px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.highlightContainer {
  font-size: 16px !important;

  ul {
    li {
      font-size: 16px !important;
      padding-top: 5px;
    }
  }
}

.upscImage {
  display: flex;
  justify-content: center;
  align-items: center;

  /* overflow: hidden; */
  img {
    max-width: none;
    /* min-height: 100%;
      min-width: 100%;
      object-fit: cover; */
  }
}

.uspWrapper {
  p {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    padding-bottom: 10px;
  }
}

.singleFeedbackBox {
  border: 1px solid #ffe8e3;
  position: relative;
  z-index: 1;
  padding: 40px;
  background-color: #fffaf3;
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;

  p {
    color: #606060;
    line-height: 1.8;

    font: {
      size: font-size;
      weight: 600;
    }

    margin: {
      top: 0;
      bottom: 0;
    }
  }

  .clientInfo {
    margin-top: 25px;

    img {
      width: 55px !important;
      height: 55px;
    }

    .title {
      margin-left: 15px;

      h3 {
        margin-bottom: 0;
        // color: main-color;
        color: $main-color;

        font: {
          size: 17px;
          weight: 700;
        }
      }

      span {
        display: block;
        // color: optional-color;
        color: $optional-color;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }

  &::before {
    content: "\f10a";
    position: absolute;
    right: 50px;
    color: #ebe6e0;
    z-index: -1;
    bottom: 25px;
    line-height: 1;

    font: {
      family: Flaticon;
      size: 100px;
    }
  }
}

// .aboutVideoBox {
//   margin-left: 30px;
//   position: relative;
//   z-index: 1;
//   border-radius: 5px;

//   .image {
//     overflow: hidden;
//     border-radius: 5px;
//     position: relative;
//     z-index: 1;

//     &::before {
//       content: "";
//       position: absolute;
//       left: 0;
//       right: 0;
//       top: 0;
//       bottom: 0;
//       border-radius: 5px;
//       background-color: $black-color;
//       //   background-color: black-color;
//       opacity: 0.3;
//       z-index: 1;
//     }
//     img {
//       border-radius: 5px;
//       //   transition: transition;
//       transition: $transition;
//     }
//   }
//   .videoBtn {
//     display: inline-block;
//     width: 80px;
//     height: 80px;
//     font-size: 35px;
//     text-align: center;
//     line-height: 80px;
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     z-index: 1;
//     transform: translateY(-50%) translateX(-50%);
//     // background-color: main-color;
//     background-color: $main-color;
//     border-radius: 50%;
//     color: $white-color;
//     // color: white-color;

//     &::after,
//     &::before {
//       content: "";
//       display: block;
//       position: absolute;
//       top: 0;
//       right: 0;
//       z-index: -1;
//       bottom: 0;
//       left: 0;
//       transition: $transition;
//       //   transition: transition;
//       border-radius: 50%;
//       border: 1px solid $main-color;
//       //   border: 1px solid main-color;
//     }
//     &::before {
//       animation: ripple 2s linear infinite;
//     }
//     &::after {
//       animation: ripple 2s linear 1s infinite;
//     }
//     &:hover {
//       //   color: white-color;
//       //   background-color: black-color;
//       color: $white-color;
//       background-color: $black-color;

//       &::before,
//       &::after {
//         border-color: $black-color;
//         // border-color: black-color;
//       }
//     }
//   }
//   &:hover {
//     .image {
//       img {
//         transform: scale3d(1.1, 1.1, 1.1);
//       }
//     }
//   }
//   .shape10 {
//     left: -6.5%;
//     bottom: -13.5%;
//     opacity: 0.5;
//   }
// }


.aboutVideoBox {
  margin-left: 4px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}

.aboutVideoBox .image {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.aboutVideoBox .image::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #221638;
  opacity: 0.1;
  z-index: 1;
}

.aboutVideoBox .image img {
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.aboutVideoBox .videoBtn {
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 35px;
  text-align: center;
  line-height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  background-color: #5172ff;
  border-radius: 50%;
  color: #ffffff;
}

.aboutVideoBox .videoBtn::after,
.aboutVideoBox .videoBtn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 1px solid #5172ff;
}

.aboutVideoBox .videoBtn::before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}

.aboutVideoBox .videoBtn::after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.aboutVideoBox .videoBtn:hover {
  color: #ffffff;
  background-color: #221638;
}

.aboutVideoBox .videoBtn:hover::before,
.aboutVideoBox .videoBtn:hover::after {
  border-color: #221638;
}

.aboutVideoBox:hover .image img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.aboutVideoBox .shape10 {
  left: -6.5%;
  bottom: -13.5%;
  opacity: 0.5;
}


/*================================================
Video Area CSS
=================================================*/
.videoBox {
  position: relative;
  border-radius: 5px;
  max-width: 800px;
  z-index: 1;

  margin: {
    left: auto;
    right: auto;
    top: 100px;
  }

  .image {
    overflow: hidden;
    border-radius: 5px;

    img {
      border-radius: 5px;
      transition: $transition;
    }
  }

  .videoBtn {
    display: inline-block;
    width: 80px;
    height: 80px;
    font-size: 35px;
    text-align: center;
    line-height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%) translateX(-50%);
    background-color: $main-color;
    border-radius: 50%;
    color: $white-color;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      left: 0;
      transition: $transition;
      border-radius: 50%;
      border: 1px solid $main-color;
    }

    &::before {
      animation: ripple 2s linear infinite;
    }

    &::after {
      animation: ripple 2s linear 1s infinite;
    }

    &:hover {
      color: $white-color;
      background-color: $black-color;

      &::before,
      &::after {
        border-color: $black-color;
      }
    }
  }

  &:hover {
    .image {
      img {
        transform: scale3d(1.1, 1.1, 1.1);
      }
    }
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.shape10 {
  position: absolute;
  left: -11%;
  bottom: -11%;
  z-index: -1;

  img {
    border-radius: 0;
    animation: moveleftbounce 4s linear infinite;
  }
}

.feedbackArea {
  position: relative;
  z-index: 1;
}

.clone {
  width: 100%;
  opacity: 0.05;
  position: absolute;
  margin-top: -60px;
  color: #333;
  text-transform: capitalize;
}