.hoverTriger {
  .hoverTarget {
    display: none !important;
  }

  &:hover {
    display: block !important;
    .hoverTarget {
      display: block !important;
    }
  }
}
